// ---------------------------
// mixins
// ---------------------------
// media mixin
// title
// scrollbar custom
// convert px to rem
// gradient background
// ---------------------------

// media mixin
// ---------------------------
// @mixin media-breakpoint-up($breakpoint) {
//     @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
//         @content;
//     }
// }

// @mixin media-breakpoint-down($breakpoint) {
//     @media (max-width: map-get($grid-breakpoints, $breakpoint) - 1) {
//         @content;
//     }
// }

// title
// ---------------------------
@mixin title() {
    color: $light;
    font-family: $font-avenir-bold;
    font-size: $font-size-base * 3 - 7px;
    line-height: $Line-height-base;
}

@mixin title-small() {
    @include title();
    font-size: 25px;
    line-height: 1.2;
}

@mixin title-langer() {
    @include title();
    font-size: 70px;
    line-height: 1.2;
    text-transform: uppercase;
}

@mixin shadow() {
    box-shadow: -2px 2px 15px rgba(0, 0, 0, 0.85);
}

@mixin text-shadow() {
    text-shadow: -2px 2px 15px rgba(0, 0, 0, 0.95);
}

// scrollbar custom
// ---------------------------
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, black, 70%)) {
    ::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(#333333, 0.5);
        border-radius: 1rem;
    }

    ::-webkit-scrollbar-track {
        background: $light;
        background: rgba(#333333, 0.1);
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

// convert px to rem
// ---------------------------
@function calculateRem($size) {
    $remSize:  calc($size / 16px);
    @return #{$remSize}rem;
}

@mixin font-size($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}

// gradient background
// ---------------------------
@mixin gradientbg($start-color, $end-color) {
    // background-color: $start-color;
    background-image: -webkit-gradient(linear,
            left top,
            right top,
            color-stop(0, $start-color),
            color-stop(1, $end-color));
    background-image: -o-linear-gradient(right, $start-color 0%, $end-color 100%);
    background-image: -moz-linear-gradient(right, $start-color 0%, $end-color 100%);
    background-image: -webkit-linear-gradient(right, $start-color 0%, $end-color 100%);
    background-image: -ms-linear-gradient(right, $start-color 0%, $end-color 100%);
    background-image: linear-gradient(to right, $start-color 0%, $end-color 100%);
}
@mixin gradientbg-topleftbottomright($start-color, $end-color) {
    // background-color: $start-color;
    background-image: -webkit-gradient(linear,
            left top,
            right bottom,
            color-stop(0, $start-color),
            color-stop(1, $end-color));
    background-image: -o-linear-gradient(right bottom, $start-color 0%, $end-color 100%);
    background-image: -moz-linear-gradient(right bottom, $start-color 0%, $end-color 100%);
    background-image: -webkit-linear-gradient(right bottom, $start-color 0%, $end-color 100%);
    background-image: -ms-linear-gradient(right bottom, $start-color 0%, $end-color 100%);
    background-image: linear-gradient(to right bottom, $start-color 0%, $end-color 100%);
}
@mixin gradient-bg-hover($start-color, $end-color) {
    background-color: $start-color;
    background-image: -webkit-gradient(linear,
            left bottom,
            right top,
            color-stop(0.5, $start-color),
            color-stop(1, $end-color));
    background-image: -o-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: -moz-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: -webkit-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: -ms-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: linear-gradient(to right top, $start-color 50%, $end-color 100%);
}

@mixin gradient-btn($start-color, $end-color) {
    background-color: $start-color;
    background-image: -webkit-gradient(linear,
            left bottom,
            right bottom,
            color-stop(0.5, $start-color),
            color-stop(1, $end-color));
    background-image: -o-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: -moz-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: -webkit-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: -ms-linear-gradient(right top, $start-color 50%, $end-color 100%);
    background-image: linear-gradient(to right top, $start-color 50%, $end-color 100%);
}

@mixin switch-button($switch-width, $switch-height, $radius) {
    $switch-height-after: $switch-height - 4px;
    $switch-width-after: $switch-height - 4px;
    $switch-padding-left: $switch-width;

    .custom-switch {
        display: inline-block;
        min-height: calculateRem($switch-height);
        padding-left: $switch-padding-left;
        vertical-align: middle;
        margin-bottom: 0;

        .custom-control-label {
            &::before {
                border-color: $border-color;
                border-radius: calculateRem($radius);
                box-sizing: border-box;
                height: calculateRem($switch-height); //20px
                left: 0;
                width: calculateRem($switch-width); //38px
            }

            &::after {
                background-color: #fff;
                box-shadow: 0 0 3px rgba(#000000, 0.2);
                height: $switch-height-after;
                left: 2px;
                top: 2px;
                width: $switch-width-after;
            }
        }

        .custom-control-label-text {
            padding-left: 0.5rem;
            word-break: break-all;
        }

        .custom-control-input {
            &:checked {
                &~.custom-control-label {
                    &::before {
                        border-color: transparent;
                        // @include gradient-bg($brand-primary, $brand-primary-light);
                    }

                    &::after {
                        transform: translateX($switch-width - $switch-height);
                    }
                }
            }

            &:focus {
                &:not(:checked) {
                    &~.custom-control-label {
                        &::before {
                            border-color: $border-color;
                        }
                    }
                }
            }

            &:not(:disabled) {
                &~.custom-control-label {
                    cursor: pointer;
                }
            }

            &:disabled {
                &~.custom-control-label {
                    opacity: 0.5;

                    &::before {
                        background-color: #fff;
                        // border-color: rgba($border-color, 0.5);
                    }
                }

                &:checked {
                    &~.custom-control-label {
                        &::before {
                            background-color: #fff;
                        }
                    }
                }
            }
            &.form-preview {

                &.disabled,
                &:disabled {
                    &~.custom-control-label {
                        opacity: 1;
                    }
                }
            }
        }

        &.custom-switch-right {
            padding-left: 0;
            padding-right: $switch-padding-left;

            .custom-control-label {
                &::before {
                    left: auto;
                    right: 0;
                }

                &::after {
                    left: auto;
                    right: 2px + $switch-width - $switch-height;
                }
            }

            .custom-control-label-text {
                padding-left: 0;
                padding-right: 0.5rem;
            }

            // .custom-control-input {
            //     &:checked {
            //         &~.custom-control-label {
            //             &::after {
            //                 transform: translateX($switch-width - $switch-height);
            //             }
            //         }
            //     }
            // }
        }
    }
}
@mixin gradient-title() {
    background: linear-gradient(90deg, #6B9674 0%, #8DB490 90.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin properties($property, $value) {
    @if not empty($value) {
        #{$property}: $value;
    }
}

@mixin media-breakpoint-up($breakpoint) {
    // If the key exists in the map
    @if map-has-key($grid-breakpoints, $breakpoint) {
      // Prints a media query based on the value
      @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
        @content;
      }
    }
   
    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($grid-breakpoints)}.";
    }
}

@mixin media-breakpoint-down($breakpoint) {
    // If the key exists in the map
    @if map-has-key($grid-breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
        // Prints a media query based on the value
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    }
   
    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($grid-breakpoints)}.";
    }
}