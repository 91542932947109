
#main {
    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        height: calc(100vh - 50px);
    }
}
#main-content {
    // flex: 0 0 calc(100% - 244px);
    // max-width: calc(100% - 244px);
    @include media-breakpoint-up(lg) {
        flex: 0 0 calc(100% - 244px);
        max-width: calc(100% - 244px);
        height: 100%;
    }
}