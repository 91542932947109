#sidebar {
    background: #F0F0F0;
    border-width: 0.5px 0.5px 0px 0px;
    border-style: solid;
    border-color: #979797;
    @include media-breakpoint-up(lg) {
        flex: 0 0 244px;
        max-width: 244px;
    }
}

.widget {
    padding: 10px 0;
    border-bottom: $border-color solid 1px;
    &-head {
        position: relative;
        padding: 10px calc($grid-gutter-width/2);
        &-control {
            position: absolute;
            right: 8px;
            top: 7px;
        }
    }
    &-content {
        padding: 10px calc($grid-gutter-width/2);
    }
    &-title {
        @include font-size(11px);
        margin-bottom: 0;
    }

    &-nav {
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            display: block;
            padding: 10px calc($grid-gutter-width/2);
            text-decoration: none;
            color: $body-color;
            @include font-size(13px);
            position: relative;

            &:hover,
            &.active,
            .active & {
                background-color: $hover-bg;
            }
            &:hover {
                .widget-nav-item-control {
                    display: block;
                }
            }
            &-control {
                position: absolute;
                right: 8px;
                top: 7px;
                display: none;
            }
        }
    }
}