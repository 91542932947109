// ---------------------------
// Colors
// ---------------------------
//overwrite colors
$primary: #002060;
$secondary: #515151;
$tertiary: #DAEBAC;
$quaternary: #22CA3D;
$quinary: #E9E590;
$success: #6DBC5B;
$info: #1A73E8;
$warning: #FFC000;
$danger: #FF364E;
$light: #fff;
$dark: #000;

$grey: #F4F3F7;
$greyish: #1F1F1F;
$grey-dark:     #7C809E;
$grey-darker:   #E9EFFD;

$warning-light:   rgba($warning, 0.25);

$background-color: $grey;
$base-color: #000;
$body-color:    $base-color;
$border-color:                #DADCE0;
$headings-color: $primary;
$link-color:    $primary;

$hover-bg: #ECF6FF;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "tertiary":   $tertiary,
  "quaternary": $quaternary,
  "quinary":    $quinary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "grey-dark":       $grey-dark,
);