$servey: '.servey';
#{$servey} {
    &-wrapper {
        height: 100%;
    }
    &-head {
        display: flex;
        justify-content: space-between;
        height: 63px;
        align-items: center;
        padding: 13px calc($grid-gutter-width/2);

        &__search {
            flex: 0 0 313px;
            max-width: 313px;
        }
        &__filter {
        }
    }

    &-body {
        height: calc(100% - 63px);
    }

    &-grid {
        > .k-grid {
            height: 100%;
        }
    }

    &-item {
        display: flex;
        &__thumb {
            flex: 0 0 30px;
            max-width: 30px;
            margin-right: 12px;
        }

        &__info {
            align-self: center
        }

        &__name {
            @include font-size(16px);
            color: $primary;
            font-weight: $font-weight-semi-bold;
        }

        &__meta {
            padding: 2px 10px;
            display: inline-flex;
            vertical-align: top;
            &s {
                @include font-size(12px);
                margin: -2px -10px;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}