// ---------------------------
// Typography
// ---------------------------
// Headings
// text color
// font size
// background color
// ---------------------------

// Headings
// ---------------------------

// @each $i,
// $value in $font-size-heading {

// 	h#{$i},
// 	.h#{$i} {
// 		font-family: $headings-font-family;
// 		font-size: $value;

// 		a {
// 			color: inherit;
// 		}
// 	}
// }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	color: $headings-color;
	font-weight: $headings-font-weight;
	margin: 0 0 10px;
	a {
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}

.text-overflow {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-description {
	// height: 68px;
	overflow: hidden;
	-webkit-line-clamp: 2;    
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

hr {
	border-top: $border-color solid 1px;
	margin: 0;
}

@each $color, $value in $theme-colors {
	.text-#{$color}{
		color: $value;
	}
  }
