// ---------------------------
// Fonts
// ---------------------------
@import "../fonts/index";

// Fonts weight
// ------------------------------------------------------
$font-weight-black:         900;
$font-weight-bolder:        800;
$font-weight-bold:          700;
$font-weight-semi-bold:     600;
$font-weight-medium:        500;
$font-weight-normal:        400;
$font-weight-light:         300;
$font-weight-lighter:       200;
$font-weight-thin:          100;

// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:  'Quicksand', sans-serif;
$font-weight:   $font-weight-medium;
$line-height-base:       calc(16.25/13);

//heading
$headings-font: $font-family-sans-serif;
$headings-font-weight:   $font-weight-semi-bold;
$headings-line-height:   calc(16/20);

// scss-docs-start font-sizes
// $font-sizes: (
//   1: 2.5rem,
//   2: 2rem,
//   3: 1.5rem,
//   4: 1.25rem,
//   5: 1rem,
//   6: 1.125rem
// ) !default;

