// ---------------------------
// Utilities
// ---------------------------

@for $i from 0 through 5 {
    .pt-#{i} {
        padding-top: #{$i*4}px;
    }
    .pr-#{i} {
        padding-right: #{$i*4}px;
    }
    .pb-#{i} {
        padding-bottom: #{$i*4}px;
    }
    .pl-#{i} {
        padding-left: #{$i*4}px;
    }
    .px-#{i} {
        padding-left: #{$i*4}px;
        padding-right: #{$i*4}px;
    }
    .py-#{i} {
        padding-top: #{$i*4}px;
        padding-bottom: #{$i*4}px;
    }

    .mt-#{i} {
        margin-top: #{$i*4}px;
    }
    .mr-#{i} {
        margin-right: #{$i*4}px;
    }
    .mb-#{i} {
        margin-bottom: #{$i*4}px;
    }
    .ml-#{i} {
        margin-left: #{$i*4}px;
    }
    .mx-#{i} {
        margin-left: #{$i*4}px;
        margin-right: #{$i*4}px;
    }
    .my-#{i} {
        margin-top: #{$i*4}px;
        margin-bottom: #{$i*4}px;
    }
}