$k-appbar: '.k-appbar';
#{$k-appbar} {
    &.k-appbar-primary {
        background-color: $primary;
    }

    .k-avatar-md {
        width: 30px;
        height: 30px;
        max-width: 30px;
    }
    .k-button-md {
        padding: 6px;
    }
}
$appbar: '.appbar';
#{$appbar} {
    &-brand {
        @include media-breakpoint-up(lg) {
            flex: 0 0 236px;
        }
    }
    &-menu {
        @include media-breakpoint-up(lg) {
            // flex: 0 0 244px;
            // max-width: 244px;
        }
    }
    &-nav {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        &-link {
            display: block;
            padding: 7px 10px;
            position: relative;
            line-height: 20px;
            -webkit-transition: all 0.2s linear 0s;
            transition: all 0.2s linear 0s;
            text-decoration: none;
            color: $light;
        }
    }
    &-actions {
        margin-left: auto;
    }
}