#header {
}

.logo, .logo-item {
    height: 28px;
    margin: 0 3px;
    text-align: left;
    max-width: 160px;
    > img {
        width: 100%;
        height: 100%;
        vertical-align: top;
        object-fit: contain;
    }
}